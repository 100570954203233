//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    data() {
        return {
            current_password: '',
            new_password: '',
            confirm_password: ''
        }
    },
    computed: {
        disableSave() {
            if (this.new_password != this.confirm_password) {
                return true;
            }

            return false;
        }
    },
    components: {
        ActionButtons
    },
    methods: {
        reset() {
            this.current_password = '',
            this.new_password = '',
            this.confirm_password = ''
        },
        changePassword() {
            this.$q.loading.show({
                message: 'Changing your password..'
            });
            this.$http({
                url: 'profiles/password',
                method: 'PUT',
                data: {
                    current_password: this.current_password,
                    new_password: this.new_password
                }
            }).then((response) => {
                this.$q.loading.hide();
                switch (response.data['status']) {
                    case 'wrong_password':
                        this.$q.notify({
                            message: 'The current password that you entered is incorrect. Please try again.',
                            color: 'negative'
                        })
                    break;
                    case 'password_updated':
                        localStorage.flash = 'Your password has been successfully updated.',
                        window.location.reload();
                    break;
                }
            }).catch((err) =>{
                if (err.response.status == 400) {
                    this.$q.notify({
                        message: 'Bad request. Please try again.',
                        color: 'negative'
                    })
                }
            })
        }
    }
}
